import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export interface AppConfig {
  course: {
    demoCourseId: string;
    templates?: {
      id: string;
      name: string;
      icon: string;
      description?: string;
    }[];
  };

  domainCNameRecord: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdminConfigService {
  private appConfig?: AppConfig;

  constructor(private http: HttpClient) {}

  get<U extends keyof AppConfig>(key: U): AppConfig[U] {
    if (!this.appConfig) {
      throw new Error('You need to call loadAppConfig before using');
    }

    return this.appConfig[key];
  }

  loadAppConfig(configFile: string) {
    return firstValueFrom(
      this.http.get(`/assets/configs/${configFile}?v=7`),
    ).then((res: any) => (this.appConfig = res));
  }

  getDemoCourseId() {
    if (!this.appConfig) {
      throw new Error('You need to call loadAppConfig before using');
    }

    return this.appConfig.course.demoCourseId;
  }

  getCourseTemplates() {
    if (!this.appConfig) {
      throw new Error('You need to call loadAppConfig before using');
    }

    return this.appConfig.course.templates;
  }
}
