/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { NotiService } from '@memberspot/frontend/shared/util/noti';
import { Customer } from '@memberspot/models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { checkCustomer } from '../subscription.helper';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard {
  constructor(
    protected query: CustomersQuery,
    protected router: Router,
    private noti: NotiService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const { schoolId } = route.params;

    return this.query.selectEntity(schoolId).pipe(
      filter((c): c is Customer => !!c?.id),
      map((customer) => checkCustomer(customer, this.noti, null, true)),
      map((allowed) =>
        allowed === true
          ? true
          : allowed === 'accountLocked'
            ? this.router.parseUrl(`${schoolId}/settings/billing/account`)
            : this.router.parseUrl(`${schoolId}/settings/billing/pricing`),
      ),
    );
  }
}
